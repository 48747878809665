<template>
  <div class="page p-p-3" style="height: 100%">
    <Breadcrumb :model="items"/>
    <Panel class="p-mt-3" header="详细信息">
      <div class="p-d-flex p-mb-3">
        <p>姓名：张三</p>
        <p class="p-ml-5">电话号码：12455667777</p>
      </div>
      <div class="p-d-flex p-mb-3">
        <p>身份证号码：1882727891798739</p>
        <p class="p-ml-5">年龄：36</p>
      </div>
      <div class="p-d-flex p-ai-center p-mb-3">
        <span>身份证正面：</span>
        <img src="../../../../public/myfile/3.jpeg" style="width: 100px">
      </div>
      <div class="p-d-flex p-ai-center p-mb-3">
        <span>身份证反面：</span>
        <img src="../../../../public/myfile/3.jpeg" style="width: 100px">
      </div>
      <div class="p-mb-3">
        <p>
          违约时间：2021-09-08
        </p>
      </div>
      <div class="p-mb-3">
        <p>
          违约原因：未按约定到场,缺勤一次。
        </p>
      </div>
      <div class="p-mb-3">
        <p>
          违约职位：现场安保
        </p>
      </div>
      <div class="p-d-flex p-ai-center p-jc-center p-mt-5">
        <Button label="解除黑名单"/>
      </div>
    </Panel>

  </div>

</template>

<script>
export default {
  name: "blackListDetail",
  data() {
    return {
      items: [
        {label: '招聘管理'},
        {label: '招聘黑名单列表', to: '/recruit/blacklist'},
        {label: '详情'},
      ],
    }
  }
}
</script>

<style scoped>

</style>